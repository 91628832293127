import React from 'react'
import { MainProvider } from './src/context/Provider'
import "./src/styles/global.css"


export const wrapRootElement = ({ element }) => {
   return (
    <MainProvider>
        {element}
    </MainProvider>
   )
 }