/* eslint-disable max-lines */
import React, { MouseEventHandler, createContext, useContext, useEffect, useState } from 'react'
import { IMainContext, IProviderProps } from '../interface/contex'

// eslint-disable-next-line no-undefined
const MainContext = createContext<IMainContext | undefined>(undefined)

export const useMain = (): any => {
   const context = useContext(MainContext)
   if (context === null) {
      throw new Error('useMain must be used within an AuthProvider')
   }
   return context
}

export const MainProvider: React.FC<IProviderProps> = ({ children }) => {

   const isClient = typeof window === 'object'

   const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
      if (isClient) {
         const storedDarkMode = localStorage.getItem('isDarkMode');
         return storedDarkMode ? JSON.parse(storedDarkMode) : false;
      }
      return false;
   });

   useEffect(() => {

      // Save dark mode state to localStorage whenever it changes
      localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
   }, [isDarkMode]);

   const toggleDarkMode = () => {
      setIsDarkMode((prevMode) => !prevMode);
   };
   const [clickCount, setClickCount] = useState(0);

   const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
      setClickCount(prevCount => prevCount + 1);
   };

   const value: IMainContext = {
      toggleDarkMode,
      isDarkMode,
      clickCount,
      handleClick,
   }
   return (
      <MainContext.Provider value={value}>
         <div className={isDarkMode ? 'dark' : 'light'}>
            {children}
         </div>
      </MainContext.Provider>
   )
}
